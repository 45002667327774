.datepicker-wrapper {
	@apply relative;
}

.datepicker-window {
	@apply absolute top-0 right-0 mt-2 top-full bg-white rounded-lg p-4 shadow-2xl z-20;
}

.datepicker-daycol {
	@apply w-8 rounded-full flex items-center justify-center text-gray-500 font-bold;
}

.datepicker-fakeday {
	@apply w-8 h-8 rounded-full text-gray-300 flex items-center justify-center pointer-events-none;
}

.datepicker-day {
	@apply w-8 h-8 rounded-full flex items-center justify-center;
}

.datepicker-day:hover {
	@apply bg-gray-200 cursor-pointer;
}

.datepicker-selected {
	@apply bg-gray-800 text-white;
}

.datepicker-selected:hover {
	@apply bg-gray-900;
}

.datepicker-today {
	@apply border-gray-500 border-2;
}
