.form-group {
	@apply mb-6;
}

.label {
	@apply block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1;
}

.input {
	@apply bg-gray-100 appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.input:focus {
	@apply outline-none bg-white border-blue-500;
}

.input-sm {
	@apply text-sm;
}

.input-xs {
	@apply text-xs;
}

.input-invalid {
	@apply border-red-500;
}

.input-file {
	@apply cursor-pointer bg-gray-100 outline-none border border-gray-300 rounded w-full p-2 text-gray-700 leading-tight flex items-center;
}

.input-file:focus,
.input-file:active {
	@apply bg-white border-blue-500;
}
