.modal {
	@apply fixed top-0 right-0 bottom-0 left-0 z-50 flex items-start justify-center overflow-y-auto;
	background-color: rgba(0, 0, 0, 0.7);
}

.modal-window {
	@apply bg-white rounded w-full max-w-lg shadow-2xl my-20;
}

.modal-header {
	@apply rounded-tl rounded-tr py-4 px-6 flex items-center justify-between;
}

.modal-title {
	@apply text-2xl text-gray-700;
}

.modal-content {
	@apply py-6 px-8;
}

.modal-footer {
	@apply rounded-bl rounded-br bg-blue-100 border-t border-gray-300 p-4 flex items-center justify-end;
}
