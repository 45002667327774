.btn-group {
	@apply flex;
}

.btn-group .input {
	@apply rounded-tr-none rounded-br-none  border-r-0;
}

.btn-group-append .btn {
	@apply rounded-tl-none rounded-bl-none h-full px-3;
}
