.table {
	@apply w-full;
}

.table th {
	@apply font-semibold text-left py-2 px-4 text-sm;
}

.table tr {
	@apply border-b border-gray-300;
}

.table tbody > tr:last-of-type {
	@apply border-b-0;
}

.table td {
	@apply py-3 px-4 text-gray-800 break-all;
}

.table.table-hover > tbody > tr:hover {
	@apply bg-gray-100;
}

.table.table-dark th {
	@apply text-gray-500;
}

.table.table-dark tr {
	@apply border-gray-700;
}
.table.table-dark td {
	@apply text-gray-400;
}

.table.table-dark > tbody > tr:hover {
	@apply border-gray-700;
}
