body {
	@apply bg-blue-400 text-gray-800;
}

.nav-link {
	@apply px-5 py-4 bg-transparent text-gray-800 border-l-4 border-transparent;
}

.nav-link:hover {
	@apply bg-blue-100;
}

.nav-link-active {
	@apply bg-blue-100 border-blue-700 text-blue-700;
}

.nav-link-active:hover {
	@apply bg-blue-100;
}

.fastest {
	animation-duration: 0.2s;
}
