.btn {
	@apply font-semibold py-2 px-3 leading-tight rounded border border-transparent inline-flex items-center;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
	transition: box-shadow 0.1s;
}

.btn:focus {
	@apply outline-none;
	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}

/** =================================================== Sizes =================================================== **/

.btn-sm {
	@apply text-sm py-1 px-2;
}

/** =================================================== None =================================================== **/

.btn-none {
	@apply bg-transparent p-0 border-0;
}

/** =================================================== Ghost =================================================== **/

.btn-ghost {
	@apply bg-transparent text-black border-transparent;
}

.btn-ghost:hover {
	@apply border-gray-400;
}

/** =================================================== Hollow =================================================== **/

.btn-hollow {
	@apply bg-transparent text-gray-900 border-gray-500;
}

.btn-hollow:hover {
	@apply bg-transparent text-gray-900 border-gray-600;
}

/** =================================================== Primary =================================================== **/

.btn-primary {
	@apply bg-blue-800 text-white antialiased;
}

.btn-primary:hover {
	@apply bg-blue-900;
}

.btn-primary-ghost {
	@apply bg-transparent text-blue-700 border-blue-400;
}

.btn-primary-ghost:hover {
	@apply bg-blue-800 text-white border-transparent antialiased;
}

/** =================================================== Secondary =================================================== **/

.btn-secondary {
	@apply bg-gray-200 text-black;
}

.btn-secondary:hover {
	@apply bg-gray-300;
}

.btn-secondary-ghost {
	@apply bg-transparent text-black border-gray-300;
}

.btn-secondary-ghost:hover {
	@apply bg-gray-200 text-white border-transparent text-black;
}

/** =================================================== Info =================================================== **/

.btn-info {
	@apply bg-blue-400 text-white antialiased;
}

.btn-info:hover {
	@apply bg-blue-500;
}

.btn-info-ghost {
	@apply bg-transparent text-blue-400 border-blue-400;
}

.btn-info-ghost:hover {
	@apply bg-blue-400 text-white border-transparent antialiased;
}

/** =================================================== Danger =================================================== **/

.btn-danger {
	@apply bg-red-500 text-white antialiased;
}

.btn-danger:hover {
	@apply bg-red-600;
}

.btn-danger-ghost {
	@apply bg-transparent text-red-500 border-red-400;
}

.btn-danger-ghost:hover {
	@apply bg-red-500 text-white border-transparent antialiased;
}

/** =================================================== Warning =================================================== **/

.btn-warning {
	@apply bg-orange-400 text-white antialiased;
}

.btn-warning:hover {
	@apply bg-orange-500;
}

.btn-warning-ghost {
	@apply bg-transparent text-orange-400 border-orange-300;
}

.btn-warning-ghost:hover {
	@apply bg-orange-400 text-white border-transparent antialiased;
}

/** =================================================== Success =================================================== **/

.btn-success {
	@apply bg-green-500 text-white antialiased;
}

.btn-success:hover {
	@apply bg-green-600;
}

.btn-success-ghost {
	@apply bg-transparent text-green-500 border-green-400;
}

.btn-success-ghost:hover {
	@apply bg-green-500 text-white border-transparent antialiased;
}

/** =================================================== Light =================================================== **/

.btn-light {
	@apply bg-gray-100 text-gray-700;
}

.btn-light:hover {
	@apply bg-gray-200;
}

.btn-light-ghost {
	@apply bg-transparent text-gray-100 border-gray-400 text-gray-700;
}

.btn-light-ghost:hover {
	@apply bg-gray-100;
}

/** =================================================== Dark =================================================== **/

.btn-dark {
	@apply bg-gray-800 text-white antialiased;
}

.btn-dark:hover {
	@apply bg-gray-900;
}

.btn-dark-ghost {
	@apply bg-transparent text-gray-100 border-gray-800 text-black;
}

.btn-dark-ghost:hover {
	@apply bg-gray-800 border-transparent text-white antialiased;
}
