@tailwind base;
@tailwind components;

@import 'misc.css';
@import 'typo.css';
@import 'buttons.css';
@import 'button-groups.css';
@import 'form.css';
@import 'modal.css';
@import 'table.css';
@import 'datepicker.css';

@tailwind utilities;
